import cx from 'classnames'

import {
  LinkLotsLookupDataResponseErrors,
  LinkLotsLookupNetworkError,
} from '~/api/operatorActions/linkCellLineLotsToCultures'
import CloseIcon from '~/components/icons/CloseIcon'
import { displayCount } from '~/utils/string'
import GroupedErrorTable from '../../GroupedErrorTable'
import { getLinkLotsLookupDataErrorGroups } from './getLinkLotsLookupDataErrorGroups'
import cs from './lookup_errors_inline_dialog.scss'

export interface LookupErrorsInlineDialogProps {
  className?: string
  onClose: () => void
  lookupErrorResponse: LinkLotsLookupDataResponseErrors | LinkLotsLookupNetworkError
}

const LookupErrorsInlineDialog = ({
  className,
  onClose,
  lookupErrorResponse,
}: LookupErrorsInlineDialogProps) => {
  const getErrorHeaderMessage = (
    lookupErrorResponse: LinkLotsLookupDataResponseErrors | LinkLotsLookupNetworkError,
  ) => {
    if (lookupErrorResponse.type === 'lookup_errors') {
      return (
        displayCount('error', lookupErrorResponse.errors.length) +
        ' looking up data via integration'
      )
    } else {
      return 'Error looking up data via integration'
    }
  }

  const getErrorBody = () => {
    if (lookupErrorResponse.type === 'network_error') {
      return <>{lookupErrorResponse.message}</>
    }
    return (
      <GroupedErrorTable
        className={cs.parseDataErrorTable}
        errorGroups={getLinkLotsLookupDataErrorGroups(lookupErrorResponse)}
      />
    )
  }

  return (
    <div className={cx(className, cs.lookupErrorsInlineDialog)}>
      <div className={cs.header}>
        <div className={cs.headerInner}>
          {getErrorHeaderMessage(lookupErrorResponse)}
        </div>
        <div className={cs.fill} />
        <CloseIcon className={cs.closeIcon} onClick={onClose} />
      </div>
      <div className={cs.content}>{getErrorBody()}</div>
    </div>
  )
}

export default LookupErrorsInlineDialog
