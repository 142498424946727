import dayjs from 'dayjs'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'
import Link from '~/components/Link'
import MetadataSidebar, { MetadataItem } from '~/components/MetadataSidebar'
import { useFeatureFlag } from '~/core/featureFlags'

import cs from './well_metadata_sidebar.scss'

const fragment = gql(`
  fragment WellMetadataSidebarFragment on WellCultureGraphQL {
    createdAt
    cellLine
    cellLineLot
    passageNumber
    parentCulture {
      id
      culturePlate {
        id
        barcode
      }
      well
    }
  }
`)

export default function WellMetadataSidebar(props: {
  culture: FragmentType<typeof fragment>
  routePrefix?: string
}) {
  const limsCultureData2024 = useFeatureFlag('limsCultureData2024')

  const culture = unmaskFragment(fragment, props.culture)
  const parent = culture.parentCulture

  const createdAt = dayjs(culture.createdAt)
  const createdThisYear = createdAt.year() === dayjs().year()

  return (
    <MetadataSidebar>
      <MetadataItem label={parent == null ? 'First Checked In' : 'Seeded'}>
        {createdAt.format(createdThisYear ? 'MMM D' : 'MMM D, YYYY')}&ensp;
        {createdAt.format('h:mm A')}&ensp;
        <span className={cs.secondary}>({createdAt.fromNow()})</span>
      </MetadataItem>

      <MetadataItem label='Parent Culture'>
        {parent ? (
          <Link
            to={`${props.routePrefix ?? ''}/plate/${parent.culturePlate.id}/well/${parent.well}`}
            variant='primary'
          >
            {parent.culturePlate.barcode} • {parent.well}
          </Link>
        ) : (
          <span className={cs.secondary}>None</span>
        )}
      </MetadataItem>

      {limsCultureData2024 ? (
        <>
          <MetadataItem label='Cell Line'>
            {culture.cellLine || <span className={cs.secondary}>None</span>}
          </MetadataItem>

          <MetadataItem label='Cell Line Lot'>
            {culture.cellLineLot || <span className={cs.secondary}>None</span>}
          </MetadataItem>

          <MetadataItem label='Passage Number'>
            {culture.passageNumber ?? <span className={cs.secondary}>None</span>}
          </MetadataItem>
        </>
      ) : null}
    </MetadataSidebar>
  )
}
