import { FocusStyleManager } from '@blueprintjs/core'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom'

import App from './core/App'
import { analytics } from './core/analytics'
import { initializeDayJS } from './utils/date'

import '~styles/main.scss'

window.appContext = JSON.parse(
  document.getElementById('app_context')?.textContent ?? null,
)

// eslint-disable-next-line no-console
console.log('Environment', {
  NODE_ENV: process.env.NODE_ENV,
  DISABLE_ERROR_REPORTING: process.env.DISABLE_ERROR_REPORTING,
  MONOMER_DESKTOP_DEFAULT_ORIGIN: process.env.MONOMER_DESKTOP_DEFAULT_ORIGIN,
  MONOMER_MONITOR_ORIGIN: process.env.MONOMER_MONITOR_ORIGIN,
  MONOMER_CLOUD_FRONTEND_AVAILABLE_AT: process.env.MONOMER_CLOUD_FRONTEND_AVAILABLE_AT,
  MONOMER_FRONTEND_DEPLOYMENT_MODE: process.env.MONOMER_FRONTEND_DEPLOYMENT_MODE,
  MONOMER_MONITOR_JWT_AUDIENCE: process.env.MONOMER_MONITOR_JWT_AUDIENCE,
  MONOMER_USE_LOCAL_ASSETS_FOR_DEMO: process.env.MONOMER_USE_LOCAL_ASSETS_FOR_DEMO,
  AUTH0_CLIENT_DOMAIN: process.env.AUTH0_CLIENT_DOMAIN,
  AWS_COMMIT_ID: process.env.AWS_COMMIT_ID,
  AWS_BRANCH: process.env.AWS_BRANCH,
  AWS_PULL_REQUEST_ID: process.env.AWS_PULL_REQUEST_ID,
  AWS_PULL_REQUEST_SOURCE_BRANCH: process.env.AWS_PULL_REQUEST_SOURCE_BRANCH,
  SENTRY_DSN: process.env.SENTRY_DSN,
})

// Note: Customers on the Powershell release channel will have the env set to
// "development" even though it's their production environment.
//
// TODO(SWE-552): Replace with a flag that doesn't conflate Powershell
// production envs with local dev.
const isDev = process.env.NODE_ENV === 'development'

if (process.env.DISABLE_ERROR_REPORTING) {
  // eslint-disable-next-line no-console
  console.warn(
    'Error reporting is DISABLED. If this is a production workcell, please remove the DISABLE_ERROR_REPORTING environment variable.',
  )
} else {
  // Ad-blockers tend to block Sentry. Sentry has a tunnel option, documented below:
  // https://docs.sentry.io/platforms/javascript/troubleshooting/#using-the-tunnel-option
  Sentry.init({
    dsn: process.env.SENTRY_DSN,

    environment: getEnvironmentForTelemetry(),
    release: process.env.AWS_COMMIT_ID,

    integrations: [
      Sentry.browserTracingIntegration({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https://desktop-demo.monomerbio.com'],
      }),
      Sentry.replayIntegration(),
    ],

    // Performance Monitoring
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance
    // monitoring.
    // As of 2024-02-26, peak frontend transaction events is 200 per day at a rate of 0.1,
    // which is ~50x less than the peak transaction rate for the desktop backend.
    // We can afford to increase this up to 100% for now to help with debugging some frontend
    // performance issues.
    // TODO: We may eventually want some sort of deployid based sampling or a dynamic sampling
    //  for app-based debug mode.
    tracesSampleRate: 1.0,
    // Session Replay
    // Sets the sample rate to 1.0 to capture 100% of transactions for session replay.
    // Default is 10% for production, 100% for development.
    // Replays have been fairly valuable for examining user flows, so let's increase the sample
    // rate for production to 50%.
    replaysSessionSampleRate: isDev ? 1.0 : 0.5,
    // If you're not already sampling the entire session, change the sample rate to 100%
    // when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
  })

  // eslint-disable-next-line no-console
  console.log('Error reporting is enabled.')
}

initAnalytics()
initializeDayJS()

const rootElement = document.createElement('div')
document.body.appendChild(rootElement)

ReactDOM.render(<App />, rootElement)

// See https://blueprintjs.com/docs/#core/accessibility.focus-management
FocusStyleManager.onlyShowFocusOnTabs()

/**
 * Get a label for the current frontend environment. The label is specific
 * enough to distinguish between our many deployment methods.
 */
function getEnvironmentForTelemetry() {
  if (process.env.AWS_BRANCH === 'master') {
    return 'amplify-production'
  }

  if (process.env.AWS_PULL_REQUEST_ID) {
    return 'amplify-pull-request-preview'
  }

  if (window.appContext) {
    return `django-${process.env.NODE_ENV}`
  }

  return process.env.NODE_ENV
}

function initAnalytics() {
  const writeKey = process.env.SEGMENT_WRITE_KEY
  if (writeKey) {
    analytics.load({ writeKey })
  } else {
    // Segment analytics are cloud-only (no desktop) for now,
    // so don't error if we don't have it
    console.warn(`Missing SEGMENT_WRITE_KEY`)
  }
}
