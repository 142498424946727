import { IdToken, useAuth0 } from '@auth0/auth0-react'
import { createContext, useContext, useEffect, useState } from 'react'

/**
 * Frontend Feature Flagging System
 * ================================
 *
 * HOW IT WORKS
 *
 *   This system allows you to set metadata on users and orgs to gate features
 *   on the frontend. The flags are evaluated in the following order:
 *
 *   - Flags set in session storage under the key `monomer_flag_overrides`.
 *     (This should only be used for temporary debugging.)
 *   - Flags set in the user's app_metadata. (This should be used sparingly.)
 *   - Flags set in the org's metadata. (This is preferred.)
 *   - The fallback value defined in `FEATURE_FLAG_DEFAULTS`.
 *
 *
 * LIMITATIONS
 *
 *   Flags can only be consumed in deployments that use Auth0 for
 *   authentication. As of 2024, this excludes the automation platform.
 *
 *   Flags are passed to the frontend using Auth0 tokens, so changes will take
 *   effect after the token expires, or the next time the user signs in.
 *
 *   Also, since Auth0 tokens are passed in various headers and URL query
 *   strings, their size is limited. If we add too many feature flags, we may
 *   encounter authentication failures. Headers are often limited to 8 KB, and
 *   as of 2024, an ID token containing a Google profile picture URL is already
 *   ~2.6 KB.
 *
 *
 * HOW TO CREATE A FEATURE FLAG
 *
 *   1. Add a property to the `FeatureFlags` interface below.
 *        - The property MUST be optional.
 *        - Making it also nullable is NOT RECOMMENDED because it creates
 *          ambiguity between `null` and `undefined`.
 *        - The property name, together with the JSON encoding of its value,
 *          SHOULD have a combined length of less than 30 characters to avoid
 *          hitting token size limits.
 *        - The property MUST have a JSDoc comment that unambiguously describes
 *          the feature gated by the flag.
 *        - For flags intended to be temporary, the property name MAY be of the
 *          form <slug><year> where `slug` is a short identifier of the feature
 *          and `year` is the year in which the flag was introduced.
 *        - Avoid using the word "new" to describe a feature, as product
 *          evolution can result in multiple flags for different versions of the
 *          same feature.
 *
 *   2. Add a default value for the property in `FEATURE_FLAG_DEFAULTS`.
 *        - You can later modify the default value, making rollout easier to
 *          manage.
 *
 *   3. Call the `useFeatureFlag` hook in a component to read the value.
 *        - The logic relying on the flag MUST gracefully handle the scenario
 *          where the flag has different values for different users of the same
 *          org.
 *        - For features that involve writing new data models, a popular
 *          approach is to gate the ability to create _new data_ behind the flag
 *          (e.g. show create button if flag is true) but show _already-created
 *          data_ to everyone, including people for whom the flag is false.
 *
 *   4. In the Auth0 management console, edit the metadata for an org, or the
 *      app_metadata for a user (NOT the regular metadata for a user).
 *        - Add a `featureFlags` object at the top level of the metadata, if one
 *          is not already present.
 *        - Set your property inside the `featureFlags` object.
 *
 *   5. To temporarily test a flag, you can use
 *      window.monomerTemporarilyOverrideFeatureFlag(), documented below.
 *
 */

/** Feature flags that can be set at org level, user level, or both. */
export interface FeatureFlags {
  /** Demo controls for sales to display custom data. */
  demoControls?: boolean

  /** Plate and well view improvements from the Monitor Browsing Revamp initiative (Fall 2024) */
  plateWellView2024?: boolean
  /** Lineage and LIMS metadata features. */
  limsCultureData2024?: boolean
}

/** Default values for feature flags. Used when the flag is unset on both user and org. */
const FEATURE_FLAG_DEFAULTS: Required<FeatureFlags> = {
  demoControls: false,

  plateWellView2024: true,
  limsCultureData2024: false,
}

/** Hook that returns the current value of a feature flag.  */
export function useFeatureFlag<K extends keyof FeatureFlags>(
  flagName: K,
): FeatureFlags[K] {
  const { overrides, userFlags, orgFlags } = useContext(FeatureFlagContext)
  return (
    overrides?.[flagName] ??
    userFlags?.[flagName] ??
    orgFlags?.[flagName] ??
    FEATURE_FLAG_DEFAULTS[flagName]
  )
}

interface ContextValue {
  overrides?: FeatureFlags
  userFlags?: FeatureFlags
  orgFlags?: FeatureFlags
}

const FeatureFlagContext = createContext<ContextValue>({})

export function FeatureFlagProvider({ children }: React.PropsWithChildren<unknown>) {
  const { getIdTokenClaims, isLoading } = useAuth0()
  const [claims, setClaims] = useState<IdToken | null>(null)
  useEffect(() => {
    const readClaims = async () => {
      const claims = await getIdTokenClaims()
      if (claims) {
        setClaims(claims)
      }
    }

    readClaims()
  }, [getIdTokenClaims, isLoading])

  const [overrides, setOverrides] = useState<FeatureFlags>(loadOverrides())
  useEffect(() => {
    window.monomerTemporarilyOverrideFeatureFlag = (key: string, value: any) => {
      const newOverrides = { ...overrides, [key]: value }
      setOverrides(newOverrides)
      saveOverrides(newOverrides)
    }
  }, [overrides, setOverrides])

  const contextValue = claims
    ? {
        overrides,
        userFlags: claims.monomer_feature_flags ?? {},
        orgFlags: claims.org_monomer_feature_flags ?? {},
      }
    : {}

  useEffect(() => {
    console.debug('Feature flags updated', contextValue)
  }, [contextValue])

  return (
    <FeatureFlagContext.Provider value={contextValue}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

declare global {
  interface Window {
    monomerTemporarilyOverrideFeatureFlag?: (key: string, value: any) => void
  }
}

function loadOverrides(): FeatureFlags {
  try {
    return JSON.parse(sessionStorage.getItem('monomer_flag_overrides') ?? '{}')
  } catch (e) {
    console.error('Error parsing feature flag overrides', e)
    return {}
  }
}

function saveOverrides(overrides: FeatureFlags) {
  sessionStorage.setItem('monomer_flag_overrides', JSON.stringify(overrides))
}
