import dayjs from 'dayjs'
import { useMemo } from 'react'

import { FragmentType, gql, unmaskFragment } from '~/__generated__'
import ConfluenceGraphSidebar, {
  ConfluenceDataset,
} from '~/components/ConfluenceGraphSidebar'

const fragment = gql(`
  fragment PlateConfluenceGraphSidebarFragment on CulturePlateGraphQL {
    plateObservationHistory {
      id
      timestamp
      confluences {
        value
        well
      }
    }
  }
`)

export default function PlateConfluenceGraphSidebar(props: {
  plate: FragmentType<typeof fragment>
}) {
  const plate = unmaskFragment(fragment, props.plate)

  const dataset: ConfluenceDataset = useMemo(
    () => ({
      name: 'Plate Average',
      data: plate.plateObservationHistory
        .filter(({ confluences }) => confluences.length > 0)
        .map(({ timestamp, confluences }) => ({
          timestamp: dayjs(timestamp),
          confluence:
            confluences.reduce((acc, { value }) => acc + value, 0) / confluences.length,
        })),
    }),
    [plate.plateObservationHistory],
  )

  if (dataset.data.length === 0 || dataset.data.every(d => d.confluence === 0)) {
    return null
  }

  return <ConfluenceGraphSidebar datasets={{ PLATE_AVG: dataset }} lineColor='#666' />
}
