import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Auth0Provider, User, useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { useEffect, useMemo, useState } from 'react'
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'

import logo from '~/assets/images/logo_monomer.svg'
import { FullPageError } from '~/components/Errors'
import Button from '~/components/buttons/Button'
import { DemoDataProvider } from '~/demoControls/DemoContext'
import DemoControlsPage from '~/demoControls/DemoControlsPage'
import AuthTokenDashboard from '~/pages/AuthTokenDashboard'
import IntegrationsDashboard from '~/pages/IntegrationsDashboard'
import LocalInstrumentsDashboard from '~/pages/LocalInstrumentsDashboard'
import NoOrg from '~/pages/NoOrg'
import TeamDashboard from '~/pages/TeamDashboard'
import UiPlayground from '~/pages/UiPlayground'
import Workcell from '~/pages/Workcell'
import CommandCenter from '~/pages/slasDemo/CommandCenter'

import workcellAPI from '~/api/desktop/workcell'
import {
  getUserEmail,
  getUserIsAdmin,
  getUserName,
  getUserOrgName,
  getUserPicture,
} from '~/core/appContextUtils'
import Cle2025Demo from '~/pages/Cle2025/Cle2025Demo'
import CleDemo from '~/pages/CleDemo'
import MonitorRoutes from '~/pages/Monitor/pages'
import OrganoidDemo from '~/pages/OrganoidDemo/OrganoidDemo'
import PlateRoutes from '~/pages/plate/PlateRoutes'
import { parseStringAsBool } from '~/utils/string'

import { AppContext } from './AppContext'
import Diagnostics from './Diagnostics'
import LeftNav from './LeftNav'
import {
  DeploymentMode,
  MultiFrontendContext,
  USER_ADMIN_ROLE,
  parseDeploymentMode,
} from './MultiFrontendContext'
import UpdateNotification from './UpdateNotification'
import { analytics } from './analytics'
import cs from './app.scss'
import { FeatureFlagProvider } from './featureFlags'

const SignedInApp = ({
  frontendContext,
}: {
  frontendContext: MultiFrontendContext
}) => {
  const history = useHistory()
  const location = useLocation()

  // Redirect to no-org page if user is not part of an organization.
  // Note: This may no longer be relevant once we migrate to Auth0
  useEffect(() => {
    if (
      frontendContext.deploymentMode === 'automation' &&
      !frontendContext.userMetadata.orgDisplayName &&
      !location.pathname.includes('no-org')
    ) {
      history.replace('/no-org')
    }
  }, [])

  const homePage = frontendContext.homePage || '/no-org'
  // Note: For now, we only support the concept of enabled integrations in the automation platform.
  // This may extend to include cloud-specific integrations in the near-future.
  const enabledIntegrations = frontendContext.orgMetadata.automationEnabledIntegrations
  const demoUseLocalAssets = frontendContext.demoUseLocalAssets

  return (
    <div className={cs.app}>
      <LeftNav frontendContext={frontendContext} />
      <UpdateNotification />
      <div className={cs.main}>
        <Switch>
          <Route exact path='/' render={() => <Redirect to={homePage} />} />
          <Route path={EXTERNAL_LOGIN_PATH} render={() => <LoginPage />} />
          <Route path='/workcell' render={() => <Workcell />} />
          <Route
            path='/command-center'
            render={() => <CommandCenter useLocalAssets={demoUseLocalAssets} />}
          />
          <Route
            path='/monitor'
            render={() => <MonitorRoutes frontendContext={frontendContext} />}
          />
          <Route path='/plate' render={() => <PlateRoutes />} />
          <Route
            path='/cle-2025'
            render={() => <Cle2025Demo useLocalAssets={demoUseLocalAssets} />}
          />
          <Route
            path='/cell-line-engineering'
            render={() => <CleDemo useLocalAssets={demoUseLocalAssets} />}
          />
          <Route
            path='/organoids'
            render={() => <OrganoidDemo useLocalAssets={demoUseLocalAssets} />}
          />
          <Route
            path='/team'
            render={() => <TeamDashboard userMetadata={frontendContext.userMetadata} />}
          />
          <Route
            path='/integrations'
            render={() => (
              <IntegrationsDashboard
                enabledIntegrations={enabledIntegrations}
                userMetadata={frontendContext.userMetadata}
              />
            )}
          />
          <Route path='/auth-tokens' render={() => <AuthTokenDashboard />} />
          <Route path='/ui' render={() => <UiPlayground />} />
          <Route path='/no-org' render={() => <NoOrg />} />
          <Route
            path='/local-instruments'
            render={() => <LocalInstrumentsDashboard />}
          />
          <Route path='/demo-controls' render={() => <DemoControlsPage />} />
          <Route path='/diagnostics' render={() => <Diagnostics />} />
        </Switch>
      </div>
    </div>
  )
}

const OuterApp = () => {
  if (window.appContext) {
    // App served from Django

    // It is safe to call a hook in this conditional because the presence of
    // window.appContext will never change during a session.
    const [frontendContext, setFrontendContext] = useState<MultiFrontendContext>(
      makeInitialFrontendContextFromLegacyAppContext(window.appContext),
    )
    useEffect(() => {
      workcellAPI.getConfig().then(config => {
        setFrontendContext(state => ({
          ...state,
          monitorEnabled: config.experimentalEnableMonitor ?? false,
        }))
      })
    }, [])

    return (
      <BrowserRouter>
        <SignedInApp frontendContext={frontendContext} />
      </BrowserRouter>
    )
  }

  // Single-page app
  const { invitation, organization } = getAuth0LoginURLParams()
  return (
    <Auth0Provider
      domain={process.env.AUTH0_CLIENT_DOMAIN!}
      clientId={process.env.AUTH0_CLIENT_ID!}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.MONOMER_MONITOR_JWT_AUDIENCE,
        invitation: invitation,
        organization: organization,
      }}
      useRefreshTokens
      cacheLocation='localstorage'
    >
      <FeatureFlagProvider>
        <DemoDataProvider>
          <BrowserRouter>
            <Auth0ClientApp />
          </BrowserRouter>
        </DemoDataProvider>
      </FeatureFlagProvider>
    </Auth0Provider>
  )
}

const Auth0ClientApp = () => {
  const apolloClient = useApolloClient()
  const { isAuthenticated, user } = useAuth0()
  useTelemetryTags(user)

  if (!isAuthenticated || !user) {
    return <LoginPage />
  }

  const frontendContext = makeFrontendContextFromAuth0User(user)
  return (
    <ApolloProvider client={apolloClient}>
      <SignedInApp frontendContext={frontendContext} />
    </ApolloProvider>
  )
}

/** Configure telemetry frameworks with user and org info. */
function useTelemetryTags(user: User | undefined) {
  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.sub, email: user.email })
      Sentry.setTag('org_id', user.org_id)

      analytics.identify(user.sub, {
        name: user.name,
        email: user.email,
        avatar: user.picture,
        company: { id: user.org_id },
      })
    }

    return () => {
      Sentry.setUser(null)
      Sentry.setTag('org_id', null)

      analytics.reset()
    }
  }, [user])
}

/** Create an Apollo client that sets Bearer tokens on requests. */
function useApolloClient() {
  const { getAccessTokenSilently, logout } = useAuth0()
  return useMemo(() => {
    const httpLink = new HttpLink({
      uri: `${process.env.MONOMER_MONITOR_ORIGIN}/graphql`,
    })

    const withAuth0Token = setContext(async (_, { headers }) => {
      let token = ''

      try {
        token = await getAccessTokenSilently()
      } catch (e) {
        // The cached refresh token might be invalid if the OAuth parameters
        // have changed since it was created. For example, a new version of the
        // frontend might specify a different client ID or scope. Auth0 throws
        // an error that says the token is "missing," but in reality the token
        // is there, just no longer valid. The user needs to re-authenticate to
        // get a new refresh token.
        if ((e as Error).message?.includes('Missing Refresh Token')) {
          console.info('Logging out due to missing refresh token')
          logout()
        }
        return { headers }
      }

      return {
        headers: { ...headers, authorization: `Bearer ${token}` },
      }
    })

    return new ApolloClient({
      link: withAuth0Token.concat(httpLink),
      cache: new InMemoryCache({
        // Note: By default, Apollo uses the Type+Id field to cache objects. This section defines
        // custom cache policies when that is not sufficient, such as when the Id is not narrow
        // enough, resulting in missed fetches.
        typePolicies: {
          // Montage Id currently corresponds to a DatasetId, which is shared amongst multiple
          // cultures. Hence, we need to utilize the cultureId as well to uniquely identify it.
          MontageGraphQL: {
            keyFields: ['id', 'culture', ['id']],
          },
        },
      }),
    })
  }, [getAccessTokenSilently])
}

function makeFrontendContextFromAuth0User(user: User): MultiFrontendContext {
  const getHomePage: Record<DeploymentMode, string> = {
    cloud: '/monitor',
    automation: '/workcell',
    hybrid: '/workcell', // arbitrarily default to workcell for testing reasons
  }
  const defaultEnabledPages: Record<DeploymentMode, string[] | 'all'> = {
    cloud: ['monitor'],
    automation: ['workcell'],
    hybrid: 'all', // for dev testing, default to all
  }
  // Note: Historically, the slack integration has always been enabled by default
  const defaultEnabledIntegrations = ['slack']
  const deploymentMode =
    parseDeploymentMode(process.env.MONOMER_FRONTEND_DEPLOYMENT_MODE) || 'cloud'

  // TODO(SWE=1274): OrgMetadata and userMetadata will be populated with localized hooks after
  //  Auth0 migration is complete.
  return {
    deploymentMode: deploymentMode,
    homePage: getHomePage[deploymentMode],
    monitorEnabled: true,
    useCoreAuth: false,
    // TODO(SWE-1291): Figure out how to make this work for dev-mode.
    demoUseLocalAssets: parseStringAsBool(
      process.env.MONOMER_USE_LOCAL_ASSETS_FOR_DEMO,
    ),
    orgMetadata: {
      automationEnabledIntegrations:
        user.org_monomer_automation_enabled_integrations || defaultEnabledIntegrations,
      automationFrontend: user.org_monomer_automation_frontend,
      enabledPages:
        user.org_monomer_enabled_pages || defaultEnabledPages[deploymentMode],
    },
    userMetadata: {
      name: user.name || '',
      picture: user.picture || '',
      email: user.email || '',
      orgDisplayName: user.org_display_name || '',
      roles: user.authz_roles || [],
    },
  }
}

function makeInitialFrontendContextFromLegacyAppContext(
  appContext: AppContext,
): MultiFrontendContext {
  return {
    deploymentMode: 'automation',
    cloudFrontend: process.env.MONOMER_CLOUD_FRONTEND_AVAILABLE_AT,
    homePage: '/workcell',
    monitorEnabled: false,
    useCoreAuth: true,
    demoUseLocalAssets: parseStringAsBool(
      process.env.MONOMER_USE_LOCAL_ASSETS_FOR_DEMO,
    ),
    orgMetadata: {
      // Note: Historically, the slack integration has always been enabled by default
      automationEnabledIntegrations: appContext.organization_config
        .enabled_integrations || ['slack'],
      // Note: Historically, we default to showing all pages if enabled_pages is not defined
      enabledPages: appContext.organization_config.enabled_pages || 'all',
    },
    userMetadata: {
      name: getUserName(appContext),
      picture: getUserPicture(appContext),
      email: getUserEmail(appContext),
      orgDisplayName: getUserOrgName(appContext),
      roles: getUserIsAdmin(appContext) ? [USER_ADMIN_ROLE] : [],
    },
  }
}

function FullPageLoading() {
  return (
    <div className={cs.fullPageContainer}>
      <div className={cs.slowLoadingIndicator}>
        <img src={logo} alt='Monomer Bio logo' width={50} height={50} />
      </div>
      <div className={cs.slowLoadingMessage}>Loading...</div>
    </div>
  )
}

function FullPageAuthenticationError({
  customFriendlyMessage,
}: {
  /**
   * Be careful with the wording of this message. See `FullPageError` for
   * guidelines.
   */
  customFriendlyMessage?: string
}) {
  const { logout } = useAuth0()

  return (
    <div className={cs.fullPageErrorContainer}>
      <FullPageError
        customFriendlyMessage={customFriendlyMessage}
        customActionButton={
          <Button
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
            label='Log Out'
            type='primary'
          />
        }
      />
    </div>
  )
}

/**
 * Redirect to the Auth0 login page. If we've recently redirected, or if we're
 * already logged in, render a button instead, to avoid a redirect loop.
 */
function LoginPage() {
  const { loginWithRedirect, isAuthenticated, isLoading: isAuth0Loading } = useAuth0()
  const { hasError: hasAuth0Error, friendlyMessage: friendlyAuth0ErrorMessage } =
    useFriendlyAuth0ErrorFromURL()

  const KEY = 'monomer_login_redirected_at'
  const lastRedirectedAt = parseInt(localStorage.getItem(KEY) ?? '0')
  const shouldRedirect =
    !isAuthenticated &&
    !isAuth0Loading &&
    !hasAuth0Error &&
    Date.now() - lastRedirectedAt > 10 * 1000

  useEffect(() => {
    if (shouldRedirect) {
      localStorage.setItem(KEY, Date.now().toString())
      loginWithRedirect()
    }
  }, [shouldRedirect, loginWithRedirect])

  if (hasAuth0Error) {
    return (
      <FullPageAuthenticationError
        customFriendlyMessage={friendlyAuth0ErrorMessage ?? undefined}
      />
    )
  }

  if (isAuth0Loading || shouldRedirect) {
    return <FullPageLoading />
  }

  return (
    <div className={cs.fullPageContainer}>
      <p className={cs.loggedOutMessage}>Log in to Monomer Bio to continue.</p>
      <Button onClick={() => loginWithRedirect()} label='Log In' type='primary' />
    </div>
  )
}

/**
 * Parse the URL for untrusted error callback parameters (for example, from
 * Auth0) and return a trusted, user-friendly error message.
 */
function useFriendlyAuth0ErrorFromURL(): {
  hasError: boolean
  friendlyMessage?: string
} {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  // NOTE: URL parameters are UNTRUSTED input. Any new cases must explicitly
  // match the error and description, and any calls-to-action should be reviewed
  // for safety. Never render arbitrary URL parameters into the UI, especially
  // in a sensitive flow like login.
  const errorUntrusted = params.get('error')
  const errorDescriptionUntrusted = params.get('error_description')

  if (!params.get('state')) {
    // Not an Auth0 error.
    return { hasError: false }
  }

  switch (errorUntrusted) {
    case null:
      // No error in URL
      return { hasError: false }

    case 'invalid_request':
      if (
        errorDescriptionUntrusted?.toLowerCase() ===
        'client requires organization membership, but user does not belong to any organization'
      ) {
        return {
          hasError: true,
          friendlyMessage:
            "You've signed in successfully, but you're not yet a member of any organizations. Please ask your Monomer support team to add you to an organization.",
        }
      }
      return { hasError: true }

    case 'access_denied':
      return { hasError: true }

    case 'unauthorized':
      if (errorDescriptionUntrusted?.startsWith('EMAIL_VERIFICATION_REQUIRED')) {
        return {
          hasError: true,
          friendlyMessage: `To finish setting up your account, please verify your email address. Look for an email with the subject "Verify your email." Once verified, log out and log in again.`,
        }
      }
      return { hasError: true }

    default:
      // We don't return a generic error message here, since it would be brittle
      // to block the entire app if there's ever a URL parameter called `error`.
      // Adding cases above should be easy enough. We can revisit this decision
      // if we notice users being confused by lots of different auth errors.
      return { hasError: false }
  }
}

/**
 * External flows (e.g. invitation email) should send the user to this path.
 */
const EXTERNAL_LOGIN_PATH = '/login'

/**
 * If we're on the EXTERNAL_LOGIN_PATH, return the (untrusted!) invitation and
 * organization params. Otherwise, return undefined for both params.
 */
function getAuth0LoginURLParams() {
  if (
    ![EXTERNAL_LOGIN_PATH, EXTERNAL_LOGIN_PATH + '/'].includes(window.location.pathname)
  ) {
    return { invitation: undefined, organization: undefined }
  }
  const params = new URLSearchParams(window.location.search)
  return {
    invitation: params.get('invitation') ?? undefined,
    organization: params.get('organization') ?? undefined,
  }
}

export default OuterApp
