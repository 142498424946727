import { useEffect } from 'react'
import linkCellLineLotsToCulturesAPI, {
  LinkLotsLookupDataRequest,
  LinkLotsLookupDataResponse,
} from '~/api/operatorActions/linkCellLineLotsToCultures'
import LoadingMessageBox from '~/components/LoadingMessageBox'
import Toaster from '~/components/Toaster'
import { useIsMounted } from '~/utils/hooks/useIsMounted'
import { displayCount } from '~/utils/string'
import ConfirmUpdatesStepDataTableTitle from './ConfirmUpdatesStepDataTableTitle'
import {
  ConfirmUpdatesStepData,
  ConfirmUpdatesStepValidData,
  LinkCellLineLotsToCulturesWizardData,
  LinkCellLineLotsToCulturesWizardStep,
  UploadDataStepValidData,
} from './LinkCellLineLotsToCulturesWizardData.interface'
import LinkLotsDataTable from './LinkLotsDataTable'
import LinkLotsPlatePreview from './LinkLotsPlatePreview'
import LinkLotsUnknownError from './LinkLotsUnknownError'
import cs from './confirm_updates_step.scss'
import {
  getLinkLotsPreviewPlates,
  getNumCultures,
  getNumPlates,
} from './parseDataResponseExtractors'

interface ConfirmUpdatesStepProps {
  stepData: ConfirmUpdatesStepData
  setStepData: (stepData: Partial<ConfirmUpdatesStepData>) => void
  uploadDataStepData: UploadDataStepValidData
  updateCurrentStep: (step: LinkCellLineLotsToCulturesWizardStep) => void
}

export const getValidStepDataIfComplete = (
  wizardData: LinkCellLineLotsToCulturesWizardData,
): ConfirmUpdatesStepValidData | null => {
  const stepData = wizardData.stepData.confirmUpdates
  if (
    stepData.lookupDataResponse !== null &&
    !stepData.lookupInProgress &&
    stepData.lookupDataResponse.type !== 'lookup_errors' &&
    stepData.lookupDataResponse.type !== 'network_error'
  ) {
    return {
      lookupDataResponse: stepData.lookupDataResponse,
    }
  }
  return null
}

export const assembleLookupRequest = (
  uploadDataStepData: UploadDataStepValidData,
): LinkLotsLookupDataRequest => {
  if (uploadDataStepData.parseDataResponse.type === 'cell_line_lots') {
    return {
      type: 'cell_line_lots',
      data: uploadDataStepData.parseDataResponse.data,
    }
  }

  return {
    type: 'cell_lines',
    data: uploadDataStepData.parseDataResponse.data,
  }
}

const ConfirmUpdatesStep = ({
  stepData,
  setStepData,
  uploadDataStepData,
  updateCurrentStep,
}: ConfirmUpdatesStepProps) => {
  const isMounted = useIsMounted()

  const lookupData = async () => {
    const request = assembleLookupRequest(uploadDataStepData)

    setStepData({
      lookupInProgress: true,
    })

    let response: LinkLotsLookupDataResponse | null = null
    try {
      response = await linkCellLineLotsToCulturesAPI.lookupData(request)
    } catch (e) {
      Toaster.show({
        message: 'Error communicating with the server.',
        intent: 'danger',
      })
      if (!isMounted()) {
        return
      }
      setStepData({
        lookupInProgress: false,
        lookupDataResponse: null,
      })
      return
    }
    if (!isMounted()) {
      return
    }
    setStepData({
      lookupInProgress: false,
      lookupDataResponse: response,
    })
    // If there are errors, return to uploadData.
    if (response.type === 'lookup_errors' || response.type === 'network_error') {
      updateCurrentStep('uploadMetadata')
    }
  }

  useEffect(() => {
    lookupData()
  }, [])

  if (stepData.lookupInProgress) {
    return (
      <LoadingMessageBox
        label='Looking up metadata via integration...'
        className={cs.loadingMessageBox}
      />
    )
  }

  // This means there was an HTTP error during lookupData.
  if (!stepData.lookupDataResponse) {
    return <LinkLotsUnknownError />
  }

  // We redirect to UploadDataStep in lookupData if there are errors.
  if (
    stepData.lookupDataResponse.type === 'lookup_errors' ||
    stepData.lookupDataResponse.type === 'network_error'
  ) {
    return null
  }

  return (
    <div className={cs.confirmUpdatesStep}>
      <div className={cs.dataTableColumn}>
        <ConfirmUpdatesStepDataTableTitle
          className={cs.confirmUpdatesStepDataTableTitle}
          lookupDataResponse={stepData.lookupDataResponse}
        />
        <LinkLotsDataTable
          className={cs.dataTable}
          lookupDataResponse={stepData.lookupDataResponse}
          parseDataResponse={uploadDataStepData.parseDataResponse}
        />
      </div>
      <div className={cs.platePreviewColumn}>
        <div className={cs.confirmUpdatesStepPlatePreviewTitle}>
          Linking{' '}
          {displayCount(
            'culture',
            getNumCultures(uploadDataStepData.parseDataResponse),
          )}{' '}
          ({displayCount('plate', getNumPlates(uploadDataStepData.parseDataResponse))})
        </div>
        <LinkLotsPlatePreview
          className={cs.platePreview}
          uploadType={uploadDataStepData.parseDataResponse.type}
          plates={getLinkLotsPreviewPlates(uploadDataStepData.parseDataResponse)}
        />
      </div>
    </div>
  )
}

export default ConfirmUpdatesStep
